import {Vue} from 'vue-class-component';
import {store} from '@/store';

export class Validation extends Vue {
    public reqEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    public store = store;

    public checkValidationError(errors: any) {
        return Object.values(errors).find((element) => {
            if(typeof element === 'boolean') {
                if(element) {
                    return true
                } else {
                    return false
                }
            }
        });
    }

    public checkValidationData(errorObj: any, check: any, property: any) {
        if (check) {
            errorObj[`${property}`] = false;
        } else {
            errorObj[`${property}`] = true;
        }
    }

    public validationEmail(email: string) {
        return this.reqEmail.test(email);
    }

    public isURL(str:string) {
        try {
            new URL(str);
            return true;
        } catch {
            return false;
        }
    }

    public validationLength(value: string, length: number) {
        return value?.length === length;
    }

    public validationFin(documentType: string, pin: string) {
        if (documentType === 'AZE' || documentType === 'AA' || documentType === 'AB') {
            return pin?.length === 7;
        }
        if (documentType === 'MY') {
            return pin?.length === 5;
        }
        if (documentType === 'DY') {
            return pin?.length === 6;
        }
    }

    public validationPhone(data: string) {
        return data.length === 18;
    }

    public validationDocumentNumber(documentType: string, documentNumber: string) {
        if (documentType === 'AZE') {
            return documentNumber?.length === 8;
        } else {
            return documentNumber?.length === 7;
        }
    }

    public validationDocumentNumberInput(documentType: string, documentNumber: string) {
        let data = documentNumber?.replace(/[^0-9]+/g, '');
        if (documentType === 'AZE') {
            return data?.slice(0, 8) ;
        } else {
            return data?.slice(0, 7);
        }
    }

    public validationPinDocumentNumberInput(documentType: string, pin: string) {
        let data = pin?.replace(/[^A-Na-n0-9P-Zp-z]/, '').toUpperCase();
        if (documentType === 'AZE' || documentType === 'AA' || documentType === 'AB') {
            return data?.slice(0, 7);
        }
        if (documentType === 'MY') {
            return data?.slice(0, 5);
        }
        if (documentType === 'DY') {
            return data?.slice(0, 6);
        }
    }

    public deletePhoneNumber(event: any, name: any, data: any, mutationName: string) {
        if ((event.keyCode === 8) || (event.keyCode === 46)) {
            if (data && data?.length <= 5) {
                let payload = {
                    type: name,
                    value: '+994('
                };
                this.store.commit(mutationName, payload);
            }
        }
    }

    public validationVoenInput(data: any) {
        return data.replace(/[^0-9]+/g, '').slice(0, 10);
    }

    public validationVoen(data: any) {
        return data?.length === 10;
    }

}
